@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,600;0,900;1,400;1,600;1,900&display=swap");

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Starter CSS starts here */
/* Read more about this CSS here:  https://www.freecodecamp.org/news/html-page-width-height/
*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input[type='password']:not(:placeholder-shown) {
  font-family: Verdana;
  letter-spacing: 8px;
  font-size: 19px;
}

/* These styles are added to disable iOS zoom.
Safari on mobile automatically zooms your app if these conditions are met:
  1) you must focus on HTML input
  2) font size in focused input is less than 16px
Provided solution is known to vary from one iOS version to another.
*/

html {
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
}
body,
#root {
  min-height: 100vh;
  max-width: 100%;
}
/* Starter CSS ends here */

/* CSS variables used by Tailwind */
:root {
  --primary: #3B6BDB;
  --primary50: #F6F8FD;
  --primary100: #DDE7FE;
  --primary200: #C4D6FE;
  --primary300: #ABC4FF;
  --primary400: #86A6F3;
  --primary500: #6089E7;
  --primary600: #3B6BDB;
  --primary700: #3663CA;
  --primary800: #325AB9;
  --primary900: #2D52A8;

  --secondary: #34405C;
  --secondary50: #F5F5F7;
  --secondary100: #E7E8EC;
  --secondary200: #DADCE1;
  --secondary300: #CCCFD6;
  --secondary400: #BBBFC8;
  --secondary500: #AAAFBB;
  --secondary600: #999FAD;
  --secondary700: #777F92;
  --secondary800: #566077;
  --secondary900: #34405C;

  --white: #ffffff;
  --gray: #34405C;
  --gray50: #F5F5F7;
  --gray100: #E7E8EC;
  --gray200: #DADCE1;
  --gray300: #CCCFD6;
  --gray400: #BBBFC8;
  --gray500: #AAAFBB;
  --gray600: #999FAD;
  --gray700: #777F92;
  --gray800: #566077;
  --gray900: #34405C;

  --func-error: #A82D2D;
  --func-error100: #FFDEDE;
  --func-success: #2DA874;
  --func-success100: #DEFFF1;
  --func-warning: #A89C2D;
  --func-warning100: #FFFCDE;
  --func-info: #0277bd;
}
