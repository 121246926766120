.react-calendar {
  max-width: 100%;
  background: white;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  outline: none;
}

.react-calendar__month-view__days {
  justify-content: space-evenly;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 28px;
  margin-bottom: 24px;
}

.react-calendar__navigation__label {
  background-color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 140% */

  letter-spacing: -0.3px;

  /* Gray/Dark 100 */

  color: #34405c;
}

.react-calendar__navigation button {
  min-width: 28px;
  background: none;
  border-radius: 10000px;
}

.react-calendar__navigation button:enabled:hover {
  background-color: #e7e8ec;
}

.react-calendar__navigation button:enabled {
  background-color: #e7e8ec;
}

.react-calendar__month-view__weekdays {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: center;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  text-decoration: none;

  /* D/800 */

  color: #566077;
}

abbr:where([title]) {
  text-decoration: none;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  /* D/100 */

  background: #e7e8ec;
  /* D/200 */

  border: 1px solid #dadce1;
  color: #777f92;
}
.react-calendar__month-view__days__day--neighboringMonth {
  background: #e7e8ec;
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  padding: 16.5px 10px;
  text-align: center;
  border-radius: 8px;
  line-height: 22px;
  display: flex;
  margin: 5.5px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #dadce1;
}

.react-calendar__tile > abbr {
  width: 20px;
}
.react-calendar__tile:disabled {
  background: #f5f5f7;
  border: 1px solid #dadce1;
  color: #cccfd6;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #dde7fe;
}
.react-calendar__tile--now {
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
}

.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
}
.react-calendar__tile--active:enabled:hover {
  background-color: #dde7fe;
}
.react-calendar__tile--active:enabled:focus {
  background: #c4d6fe;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.tiletile {
  flex: 0 0 10% !important;
  overflow: visible !important;
}
